// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "x_tw d_gS d_cw d_dv";
export var quoteParagraphCenter = "x_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "x_gV d_gV d_cw d_dx";
export var quoteRowLeft = "x_tx d_bG";
export var quoteRowCenter = "x_ty d_bD";
export var quoteRowRight = "x_tz d_bH";
export var quoteWrapper = "x_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "x_gR d_gR";
export var quoteExceptionSmall = "x_rQ q_rQ";
export var quoteExceptionNormal = "x_rR q_rR";
export var quoteExceptionLarge = "x_rS q_rS";
export var quoteAuthorExceptionSmall = "x_rT q_rT";
export var quoteAuthorExceptionNormal = "x_rV q_rV";
export var quoteAuthorExceptionLarge = "x_rW q_rW";