// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "v_jQ d_jQ d_cv";
export var galleryMasonryImage = "v_jP d_jP d_w d_bR d_dB";
export var alignLeft = "v_qD d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_qF d_fr d_bH d_dx";
export var galleryContainer = "v_tl d_dW";
export var galleryContainerFull = "v_tm d_dT";
export var galleryRowWrapper = "v_tn d_cc";
export var galleryGuttersImage = "v_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "v_jR d_jR d_K d_cD";
export var galleryTextGutters = "v_jM d_jM d_cw";
export var galleryTextNoGutters = "v_jN d_jN d_cw";
export var galleryTextMasonry = "v_tp d_jM d_cw";
export var galleryImageWrapper = "v_tq d_fg d_Z";
export var descText = "v_tr d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "v_ts";