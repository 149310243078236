// extracted by mini-css-extract-plugin
export var campaignContainer = "m_qh d_dW";
export var campaignContainerFull = "m_qj d_dT";
export var wrapper = "m_qk d_w";
export var leftWrapper = "m_ql d_bz d_bN";
export var rightWrapper = "m_qm";
export var subtitle = "m_qn";
export var title = "m_qp";
export var text = "m_qc d_cs";
export var inlineWrapper = "m_qq d_bC d_bG d_w";
export var btnsWrapper = "m_qr m_qq d_bC d_bG d_w d_bJ";
export var priceWrapper = "m_qs m_qq d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "m_qt";
export var disclaimerWrapper = "m_qv d_cD";
export var mediaSlot = "m_qw";
export var listWrapper = "m_qx d_bC d_bP d_w";
export var btnWrapper = "m_d2";
export var imageWrapper = "m_qy d_fg d_Z";
export var SmallSmall = "m_qz q_qz q_qT q_rf";
export var SmallNormal = "m_qB q_qB q_qT q_rg";
export var SmallLarge = "m_qC q_qC q_qT q_rc";
export var textLeft = "m_dv";
export var textCenter = "m_dw";
export var textRight = "m_dx";
export var alignLeft = "m_qD";
export var alignCenter = "m_bP";
export var alignRight = "m_qF";