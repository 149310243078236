// extracted by mini-css-extract-plugin
export var lbContainerOuter = "G_wr";
export var lbContainerInner = "G_ws";
export var movingForwards = "G_wt";
export var movingForwardsOther = "G_wv";
export var movingBackwards = "G_ww";
export var movingBackwardsOther = "G_wx";
export var lbImage = "G_wy";
export var lbOtherImage = "G_wz";
export var prevButton = "G_wB";
export var nextButton = "G_wC";
export var closing = "G_wD";
export var appear = "G_wF";